input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@font-face {
  font-family: myFirstFont;
  src: url(/src/Components/Asset/Font/SolaimanLipi_2.ttf);
}

* {
  font-family: myFirstFont;
}
